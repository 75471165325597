<template>
  <form class="modal-inner-container">
    <div class="w-full">
      <div class="text-n-4xl font-bold">
        {{$t('security.twofa.disabled')}}
      </div>

      <div class="text-n-md font-medium mt-n-5xl">
        {{$t('security.twofa.twofa_has_been_disabled')}}
      </div>
    </div>
    
    <ButtonV2
      @onClick="hideModal"
      :label="$t('navigation.close')"
      testId="btn-close"
      class="mt-n-5xl"
      wide
    />
  </form>
</template>

<script>
  import ButtonV2 from '@/stories/misc/ButtonV2';

  export default {
    name: 'TwoFactorAuthenticationDisabled',
    components: {
      ButtonV2,
    },
  };
</script>
